/* Lifted from https://github.com/Leaflet/Leaflet/pull/7612/files */

.leaflet-darkmode .leaflet-bar a,
.leaflet-darkmode .leaflet-bar a:hover {
  background-color: #333;
  border-bottom: 1px solid #444;
  color: #ccc;
}

.leaflet-darkmode .leaflet-bar a:hover {
  background-color: #444;
}

.leaflet-darkmode .leaflet-bar a.leaflet-disabled {
  background-color: #444;
  color: #bbb;
}

.leaflet-darkmode .leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #333;
}
.leaflet-darkmode .leaflet-control-layers-expanded {
  color: #ccc;
  background: #333;
}
.leaflet-darkmode .leaflet-control-layers-separator {
  border-top: 1px solid #444;
}

.leaflet-darkmode .leaflet-container .leaflet-control-attribution {
  background: rgba(51, 51, 51, 0.7);
}
.leaflet-darkmode .leaflet-container .leaflet-control-attribution a {
  color: #70d6ff;
}
.leaflet-darkmode .leaflet-control-attribution,
.leaflet-darkmode .leaflet-control-scale-line {
  color: #ccc;
}
.leaflet-darkmode .leaflet-control-scale-line {
  border: 2px solid #444;
  border-top: none;
  background: rgba(51, 51, 51, 0.6);
}

.leaflet-darkmode .leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #444;
  border-bottom: none;
}
.leaflet-darkmode
  .leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #444;
}
.leaflet-darkmode .leaflet-touch .leaflet-control-layers,
.leaflet-darkmode .leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.leaflet-darkmode .leaflet-popup-content-wrapper,
.leaflet-darkmode .leaflet-popup-tip {
  background: #333;
  color: #ccc;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
.leaflet-darkmode .leaflet-container a.leaflet-popup-close-button {
  color: #444;
}
.leaflet-darkmode .leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}
.leaflet-darkmode .leaflet-popup-scrolled {
  border-bottom: 1px solid #444;
  border-top: 1px solid #444;
}
.leaflet-darkmode.leaflet-oldie .leaflet-control-zoom,
.leaflet-darkmode.leaflet-oldie .leaflet-control-layers,
.leaflet-darkmode.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-darkmode.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-darkmode .leaflet-tooltip {
  background-color: #333;
  border: 1px solid #333;
  color: #ccc;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-darkmode .leaflet-tooltip-top:before {
  border-top-color: #333;
}
.leaflet-darkmode .leaflet-tooltip-bottom:before {
  border-bottom-color: #333;
}
.leaflet-darkmode .leaflet-tooltip-left:before {
  border-left-color: #333;
}
.leaflet-darkmode .leaflet-tooltip-right:before {
  border-right-color: #333;
}
